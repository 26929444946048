/**
 * @generated SignedSource<<8ac8195da054728bea348b0e2cd11243>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EdsJobSync = "EDS_JOB_SYNC_CUSTOM_ATTRIBUTES" | "EDS_JOB_SYNC_CUSTOM_EVENTS" | "EDS_JOB_SYNC_ECOMMERCE_ADD_TO_CARTS" | "EDS_JOB_SYNC_ECOMMERCE_PRODUCT_VIEWS" | "EDS_JOB_SYNC_ECOMMERCE_PURCHASES" | "EDS_JOB_SYNC_MOBILE_WALLET" | "EDS_JOB_SYNC_PRODUCT_CATALOG" | "EDS_JOB_SYNC_SUBSCRIBER_OPT_INS" | "EDS_JOB_SYNC_SUBSCRIBER_OPT_OUTS" | "EDS_JOB_SYNC_TEXT_SEND" | "EDS_JOB_SYNC_TEXT_SEND_BULK" | "EDS_JOB_SYNC_UNKNOWN" | "EDS_JOB_SYNC_USER_IDENTIFIERS" | "%future added value";
export type EdsJobSyncMethod = "EDS_JOB_SYNC_METHOD_AMAZON_S3" | "EDS_JOB_SYNC_METHOD_ATTENTIVE_SFTP" | "EDS_JOB_SYNC_METHOD_CLIENT_HOSTED_SFTP" | "EDS_JOB_SYNC_METHOD_MANUAL_UPLOAD" | "EDS_JOB_SYNC_METHOD_S3" | "EDS_JOB_SYNC_METHOD_SFTP" | "EDS_JOB_SYNC_METHOD_SNOWFLAKE_DATA_SHARE" | "EDS_JOB_SYNC_METHOD_UNKNOWN" | "%future added value";
export type SyncStatus = "SYNC_STATUS_ACTIVE" | "SYNC_STATUS_INACTIVE" | "SYNC_STATUS_UNKNOWN" | "%future added value";
export type ListSyncsFilter = {
  syncMethod?: EdsJobSyncMethod | null;
  syncStatus?: SyncStatus | null;
  syncType?: EdsJobSync | null;
};
export type SyncList_EDSLib_Query$variables = {
  after?: string | null;
  companyId: string;
  filter: ListSyncsFilter;
  first?: number | null;
};
export type SyncList_EDSLib_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useSyncPagination_EDSLib_query">;
};
export type SyncList_EDSLib_Query$rawResponse = {
  readonly node: {
    readonly __typename: "Company";
    readonly dataSyncs: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly id: string;
          readonly latestSync: SerializedDateTime | null;
          readonly name: string;
          readonly schedule: {
            readonly schedule: {
              readonly __typename: "Cron";
              readonly cronString: string;
            } | {
              readonly __typename: "OneTime";
              readonly runsImmediately: boolean;
            } | {
              readonly __typename: string;
            } | null;
          };
          readonly status: SyncStatus;
          readonly syncId: string;
          readonly syncMethod: EdsJobSyncMethod;
          readonly syncType: EdsJobSync;
        };
      }>;
      readonly pageInfo: {
        readonly endCursor: string;
        readonly hasNextPage: boolean;
      };
      readonly totalCount: number;
    } | null;
    readonly id: string;
  } | {
    readonly __typename: string;
    readonly id: string;
  } | null;
};
export type SyncList_EDSLib_Query = {
  rawResponse: SyncList_EDSLib_Query$rawResponse;
  response: SyncList_EDSLib_Query$data;
  variables: SyncList_EDSLib_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SyncList_EDSLib_Query",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "useSyncPagination_EDSLib_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "SyncList_EDSLib_Query",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "after",
                    "variableName": "after"
                  },
                  {
                    "kind": "Variable",
                    "name": "filter",
                    "variableName": "filter"
                  },
                  {
                    "kind": "Variable",
                    "name": "first",
                    "variableName": "first"
                  }
                ],
                "concreteType": "Syncs",
                "kind": "LinkedField",
                "name": "dataSyncs",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SyncEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Sync",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "syncMethod",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "syncId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "syncType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "latestSync",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SyncSchedule",
                            "kind": "LinkedField",
                            "name": "schedule",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "schedule",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "cronString",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "Cron",
                                    "abstractKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "runsImmediately",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "OneTime",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "de049c7309d5302cb1e9a0dd44eab8ee",
    "id": null,
    "metadata": {},
    "name": "SyncList_EDSLib_Query",
    "operationKind": "query",
    "text": "query SyncList_EDSLib_Query(\n  $companyId: ID!\n  $first: Int\n  $after: String\n  $filter: ListSyncsFilter!\n) {\n  ...useSyncPagination_EDSLib_query\n}\n\nfragment SyncTableRow_EDSLib_Sync on Sync {\n  id\n  name\n  status\n  syncMethod\n  syncId\n  syncType\n  latestSync\n  schedule {\n    ...label_getScheduleLabel_EDSLib_SyncSchedule\n  }\n}\n\nfragment label_getScheduleLabel_EDSLib_SyncSchedule on SyncSchedule {\n  schedule {\n    __typename\n    ... on Cron {\n      cronString\n    }\n    ... on OneTime {\n      runsImmediately\n    }\n  }\n}\n\nfragment usePaginatedDataFragment_pageInfo on PageInfo {\n  endCursor\n  hasNextPage\n}\n\nfragment useSyncPagination_EDSLib_query on Query {\n  node(id: $companyId) {\n    __typename\n    ... on Company {\n      dataSyncs(after: $after, first: $first, filter: $filter) {\n        totalCount\n        pageInfo {\n          ...usePaginatedDataFragment_pageInfo\n        }\n        edges {\n          cursor\n          node {\n            id\n            ...SyncTableRow_EDSLib_Sync\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fd0c0c21b623eb4048fedd9a0d7294a7";

export default node;
